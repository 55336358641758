/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Experts API
 * The Experts API allows human & synth experts to annotate data.  ### Annotate chats  1. Get open tasks: `GET /v1/organizations/<orgId>/tasks?state=open`. 2. For each task, fetch the associated chat: `GET /v1/tasks/<taskId>/chat/turns`. 3. Annotate the last turn, and set the annotations `POST /v1/tasks/<taskId>/chat/turns/<turnId>/candidates/<candidateId>/annotations`. 4. Complete the task `POST /v1/tasks/transition/done/<taskId>`. 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://annotation.characterai.dev/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AnnotationCategory
 */
export interface AnnotationCategory {
    /**
     * The name of the category as displayed in the user interface.
     * @type {string}
     * @memberof AnnotationCategory
     */
    name?: string;
    /**
     * A list of annotations that are displayed under this category.
     * @type {Array<AnnotationType>}
     * @memberof AnnotationCategory
     */
    annotations?: Array<AnnotationType>;
}
/**
 * 
 * @export
 * @interface AnnotationOntology
 */
export interface AnnotationOntology {
    /**
     * Named categories of annotations in the order they will appear in the user interface.
     * @type {Array<AnnotationCategory>}
     * @memberof AnnotationOntology
     */
    categories?: Array<AnnotationCategory>;
    /**
     * A list of questions to be asked of the annotator.
     * @type {Array<string>}
     * @memberof AnnotationOntology
     */
    questions?: Array<string>;
    /**
     * A list of questions to be asked of the annotator, answered with freeform text.
     * @type {Array<string>}
     * @memberof AnnotationOntology
     */
    freeform_text?: Array<string>;
}
/**
 * 
 * @export
 * @interface AnnotationType
 */
export interface AnnotationType {
    /**
     * The label for the annotation as stored in the data warehouse.
     * @type {string}
     * @memberof AnnotationType
     */
    label?: string;
    /**
     * A JSON object of options for the annotation.
     * @type {any}
     * @memberof AnnotationType
     */
    options?: any;
}
/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * The API key id.
     * @type {string}
     * @memberof ApiKey
     */
    api_key_id?: string;
    /**
     * The name of the API key.
     * @type {string}
     * @memberof ApiKey
     */
    name: string;
    /**
     * The scopes that can be used with the key.
     * @type {Array<ApiKeyScope>}
     * @memberof ApiKey
     */
    scopes: Array<ApiKeyScope>;
}
/**
 * 
 * @export
 * @interface ApiKeyScope
 */
export interface ApiKeyScope {
    /**
     * 
     * @type {ApiKeyScopeName}
     * @memberof ApiKeyScope
     */
    name: ApiKeyScopeName;
    /**
     * The scope can be granted to individual resources. Individual resources are represented as string values. 
     * @type {Array<string>}
     * @memberof ApiKeyScope
     */
    resources: Array<string>;
}
/**
 * The scope to use. 
 * @export
 * @enum {string}
 */
export enum ApiKeyScopeName {
    UserScopeReadTask = <any> 'UserScopeReadTask',
    UserScopeWriteTaskState = <any> 'UserScopeWriteTaskState',
    UserScopeWriteTask = <any> 'UserScopeWriteTask',
    UserScopeReadOrg = <any> 'UserScopeReadOrg',
    UserScopeWriteOrg = <any> 'UserScopeWriteOrg',
    UserScopeReadChat = <any> 'UserScopeReadChat',
    UserScopeWriteChat = <any> 'UserScopeWriteChat',
    UserScopeReadOrgUser = <any> 'UserScopeReadOrgUser',
    UserScopeWriteOrgUser = <any> 'UserScopeWriteOrgUser',
    UserScopeReadApiKeys = <any> 'UserScopeReadApiKeys',
    UserScopeWriteApiKeys = <any> 'UserScopeWriteApiKeys',
    UserScopeModelServerCompletions = <any> 'UserScopeModelServerCompletions'
}
/**
 * 
 * @export
 * @interface ApiKeyWithKey
 */
export interface ApiKeyWithKey {
    /**
     * The key to use in the `Authorization` header.
     * @type {string}
     * @memberof ApiKeyWithKey
     */
    key: string;
    /**
     * The API key id.
     * @type {string}
     * @memberof ApiKeyWithKey
     */
    api_key_id?: string;
    /**
     * The name of the API key.
     * @type {string}
     * @memberof ApiKeyWithKey
     */
    name: string;
    /**
     * The scopes that can be used with the key.
     * @type {Array<ApiKeyScope>}
     * @memberof ApiKeyWithKey
     */
    scopes: Array<ApiKeyScope>;
}
/**
 * 
 * @export
 * @interface ApiKeysInfo
 */
export interface ApiKeysInfo {
    /**
     * 
     * @type {Array<ApiKey>}
     * @memberof ApiKeysInfo
     */
    keys: Array<ApiKey>;
}
/**
 * Authentication information for the user.
 * @export
 * @interface AuthInfo
 */
export interface AuthInfo {
    /**
     * 
     * @type {AuthLevel}
     * @memberof AuthInfo
     */
    auth_level: AuthLevel;
}
/**
 * The authentication level of the user.
 * @export
 * @enum {string}
 */
export enum AuthLevel {
    Admin = <any> 'admin',
    Standard = <any> 'standard',
    None = <any> 'none'
}
/**
 * 
 * @export
 * @interface Candidate
 */
export interface Candidate {
    /**
     * The candidate id.
     * @type {string}
     * @memberof Candidate
     */
    candidate_id?: string;
    /**
     * The text of the message.
     * @type {string}
     * @memberof Candidate
     */
    raw_content: string;
    /**
     * An optional list of annotation types to be added to the candidate.
     * @type {Array<string>}
     * @memberof Candidate
     */
    annotations?: Array<string>;
}
/**
 * 
 * @export
 * @interface CommentInfo
 */
export interface CommentInfo {
    /**
     * The id of the annotator who created the comment.
     * @type {string}
     * @memberof CommentInfo
     */
    annotator_id: string;
    /**
     * 
     * @type {Role}
     * @memberof CommentInfo
     */
    role: Role;
    /**
     * Comment message content.
     * @type {string}
     * @memberof CommentInfo
     */
    message: string;
    /**
     * The id of the comment.
     * @type {string}
     * @memberof CommentInfo
     */
    comment_id?: string;
    /**
     * The time the comment was last updated. Currently, comments cannot be edited, so this corresponds to the create time.
     * @type {Date}
     * @memberof CommentInfo
     */
    last_update_time?: Date;
}
/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * The error message.
     * @type {string}
     * @memberof ErrorMessage
     */
    message: string;
}
/**
 * 
 * @export
 * @interface NewChat
 */
export interface NewChat extends TurnList {
    /**
     * The id of the character used when creating a new chat. If not set, a default AI character is used. 
     * @type {string}
     * @memberof NewChat
     */
    character_id?: string;
}
/**
 * 
 * @export
 * @interface NewChatAnnotations
 */
export interface NewChatAnnotations {
    /**
     * The annotations set in the candidate.
     * @type {Array<string>}
     * @memberof NewChatAnnotations
     */
    annotations?: Array<string>;
}
/**
 * 
 * @export
 * @interface NewTask
 */
export interface NewTask {
    /**
     * If set, defines the template used to provide the default values for the task.
     * @type {string}
     * @memberof NewTask
     */
    template_id?: string;
    /**
     * The case sensitive organization id of the organization that the task belongs to.
     * @type {string}
     * @memberof NewTask
     */
    organization_id?: string;
    /**
     * If set, the email address of the user to assign the task to.
     * @type {string}
     * @memberof NewTask
     */
    assignee_email?: string;
    /**
     * A unique name for the experiment being performed. e.g. ${GOAL}-${CREATOR_EMAIL}-{$DATE}.
     * @type {string}
     * @memberof NewTask
     */
    experiment_name?: string;
    /**
     * The id for the user creating the task. This is used for tracking the creator of the task.
     * @type {string}
     * @memberof NewTask
     */
    creator_id?: string;
    /**
     * Instructions to be read by the annotator before performing the task.
     * @type {string}
     * @memberof NewTask
     */
    instructions?: string;
    /**
     * 
     * @type {TaskDisplayMode}
     * @memberof NewTask
     */
    display_mode?: TaskDisplayMode;
    /**
     * If set, this chat is copied and the copy is used as a new chat. If not set, a new chat is created.
     * @type {string}
     * @memberof NewTask
     */
    source_chat_id?: string;
    /**
     * 
     * @type {NewChat}
     * @memberof NewTask
     */
    chat?: NewChat;
    /**
     * If set, the conversation starts from this turn id.
     * @type {string}
     * @memberof NewTask
     */
    source_start_turn_id?: string;
    /**
     * If set, the conversation ends with this turn id.
     * @type {string}
     * @memberof NewTask
     */
    source_end_turn_id?: string;
    /**
     * The user id of the person who owns the source chat. It must be set if `source_chat_id` is set. 
     * @type {string}
     * @memberof NewTask
     */
    source_chat_owner_id?: string;
    /**
     * 
     * @type {AnnotationOntology}
     * @memberof NewTask
     */
    annotation_ontology?: AnnotationOntology;
    /**
     * An object that is attached to the task template. The `user` key is a key/value pair displayed in the Web UI and it can be used to display information that is useful to complete a task. 
     * @type {any}
     * @memberof NewTask
     */
    metadata?: any;
    /**
     * The URL of the model server to be used to generate responses.
     * @type {string}
     * @memberof NewTask
     */
    model_server_url?: string;
    /**
     * If true, the task is created with state Done.
     * @type {boolean}
     * @memberof NewTask
     */
    autocomplete?: boolean;
}
/**
 * 
 * @export
 * @interface NewUser
 */
export interface NewUser {
    /**
     * The email address of the user that has a character.ai account associated with it.
     * @type {string}
     * @memberof NewUser
     */
    email: string;
}
/**
 * 
 * @export
 * @interface OntologyTaskIdBody
 */
export interface OntologyTaskIdBody {
    /**
     * 
     * @type {AnnotationOntology}
     * @memberof OntologyTaskIdBody
     */
    annotation_ontology?: AnnotationOntology;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * A unique ID for the organization.
     * @type {string}
     * @memberof Organization
     */
    organization_id: string;
    /**
     * Unique name of the Jira project. If not provided, organization ID is used.
     * @type {string}
     * @memberof Organization
     */
    jira_project_name?: string;
    /**
     * Description of the Jira project.
     * @type {string}
     * @memberof Organization
     */
    jira_project_description?: string;
    /**
     * Email of the Jira user to assign as project admin. If not provided, the default admin email is used.
     * @type {string}
     * @memberof Organization
     */
    jira_admin_email?: string;
    /**
     * An optional webhook URL to forward a task when it's created.
     * @type {string}
     * @memberof Organization
     */
    webhook_url?: string;
    /**
     * An optional map for headers to be sent in the webhook request.
     * @type {any}
     * @memberof Organization
     */
    webhook_request_headers?: any;
    /**
     * 
     * @type {WorkflowVersion}
     * @memberof Organization
     */
    workflow_version?: WorkflowVersion;
}
/**
 * 
 * @export
 * @interface OrganizationsOrgIdBody
 */
export interface OrganizationsOrgIdBody {
    /**
     * 
     * @type {WorkflowVersion}
     * @memberof OrganizationsOrgIdBody
     */
    workflow_version?: WorkflowVersion;
}
/**
 * The roles an annotator can assume in the annotation tool.
 * @export
 * @enum {string}
 */
export enum Role {
    Annotator = <any> 'annotator',
    Manager = <any> 'manager'
}
/**
 * 
 * @export
 * @interface ShortTaskInfo
 */
export interface ShortTaskInfo {
    /**
     * The unique identifier of the task.
     * @type {string}
     * @memberof ShortTaskInfo
     */
    task_id: string;
}
/**
 * 
 * @export
 * @interface ShortTasksInfo
 */
export interface ShortTasksInfo {
    /**
     * The list of tasks.
     * @type {Array<ShortTaskInfo>}
     * @memberof ShortTasksInfo
     */
    tasks: Array<ShortTaskInfo>;
}
/**
 * 
 * @export
 * @interface SingleTurn
 */
export interface SingleTurn {
    /**
     * A list of candidates for the turn.
     * @type {Array<Candidate>}
     * @memberof SingleTurn
     */
    candidates: Array<Candidate>;
    /**
     * Whether the message was sent by a human or a character.
     * @type {boolean}
     * @memberof SingleTurn
     */
    is_human: boolean;
    /**
     * The display name of the entity who sent the message.
     * @type {string}
     * @memberof SingleTurn
     */
    author_name: string;
    /**
     * The turn id.
     * @type {string}
     * @memberof SingleTurn
     */
    turn_id?: string;
}
/**
 * The mode in which a task is displayed. * sft, Supervised fine tuning: requires no classification and no model generation. * dpo, Direct preference optimization: requires no classification. * classi: classification, allowed on all turns and candidates. * classi_last_turn_only: classification, only enabled for the last turn. Cannot create turns. * sxs_last_turn_only: Preference evaluation given a question for the last turn in a chat. Cannot create turns. * sxs_chatbot_pref_eval: Chatbot-arena style preference evaluation. Chat with two models (headless) and make a judgement. * sxs_pref_eval: Chatbot-arena style preference evaluation. Chat with two models (Character) and make a judgement. 
 * @export
 * @enum {string}
 */
export enum TaskDisplayMode {
    Sft = <any> 'sft',
    Dpo = <any> 'dpo',
    Classi = <any> 'classi',
    ClassiLastTurnOnly = <any> 'classi_last_turn_only',
    SxsLastTurnOnly = <any> 'sxs_last_turn_only',
    SxsChatbotPrefEval = <any> 'sxs_chatbot_pref_eval',
    SxsPrefEval = <any> 'sxs_pref_eval'
}
/**
 * 
 * @export
 * @interface TaskInfo
 */
export interface TaskInfo {
    /**
     * The unique identifier of the task.
     * @type {string}
     * @memberof TaskInfo
     */
    task_id?: string;
    /**
     * A unique name for the experiment being performed.
     * @type {string}
     * @memberof TaskInfo
     */
    experiment_name?: string;
    /**
     * The URL of the model server to be used to generate responses.
     * @type {string}
     * @memberof TaskInfo
     */
    model_server_url?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    creator_id?: string;
    /**
     * The email address of the user with role Annotator assigned to the issue, null otherwise.
     * @type {string}
     * @memberof TaskInfo
     */
    assigned_user_email?: string;
    /**
     * The email address of the user with role Reviewer assigned to the issue, null otherwise.
     * @type {string}
     * @memberof TaskInfo
     */
    assigned_reviewer_email?: string;
    /**
     * Instructions to be read by the annotator before performing the task.
     * @type {string}
     * @memberof TaskInfo
     */
    instructions?: string;
    /**
     * The chat id used to perform the task.
     * @type {string}
     * @memberof TaskInfo
     */
    primary_chat_id?: string;
    /**
     * The owner id of the chat used to perform the task.
     * @type {string}
     * @memberof TaskInfo
     */
    primary_owner_id?: string;
    /**
     * 
     * @type {TaskState}
     * @memberof TaskInfo
     */
    state?: TaskState;
    /**
     * 
     * @type {TaskDisplayMode}
     * @memberof TaskInfo
     */
    display_mode?: TaskDisplayMode;
    /**
     * 
     * @type {{ [key: string]: Date; }}
     * @memberof TaskInfo
     */
    duplicate_status?: { [key: string]: Date; };
    /**
     * An object that is attached to the task template. The `user` key is a key/value pair displayed in the Web UI and it can be used to display information that is useful to complete a task. 
     * @type {any}
     * @memberof TaskInfo
     */
    metadata?: any;
    /**
     * 
     * @type {AnnotationOntology}
     * @memberof TaskInfo
     */
    annotation_ontology?: AnnotationOntology;
    /**
     * If set, defines the template used to provide the default values for the task.
     * @type {string}
     * @memberof TaskInfo
     */
    template_id?: string;
    /**
     * 
     * @type {WorkflowVersion}
     * @memberof TaskInfo
     */
    workflow_version?: WorkflowVersion;
}
/**
 * The states a task can be in.
 * @export
 * @enum {string}
 */
export enum TaskState {
    Open = <any> 'open',
    Wip = <any> 'wip',
    NeedsReview = <any> 'needs_review',
    InReview = <any> 'in_review',
    Done = <any> 'done',
    DoneBad = <any> 'done_bad',
    Punted = <any> 'punted',
    NeedsChanges = <any> 'needs_changes',
    MakingChanges = <any> 'making_changes',
    Reviewed = <any> 'reviewed'
}
/**
 * The task states to filter tasks by.
 * @export
 * @enum {string}
 */
export enum TaskStateFilter {
    Open = <any> 'open',
    Wip = <any> 'wip',
    Done = <any> 'done',
    Review = <any> 'review',
    AllActive = <any> 'all_active',
    Deprecated = <any> 'deprecated',
    All = <any> 'all'
}
/**
 * 
 * @export
 * @interface TasksInfo
 */
export interface TasksInfo {
    /**
     * The list of tasks assigned to the user
     * @type {Array<TaskInfo>}
     * @memberof TasksInfo
     */
    tasks?: Array<TaskInfo>;
}
/**
 * 
 * @export
 * @interface ThreadAndCommentsInfo
 */
export interface ThreadAndCommentsInfo {
    /**
     * 
     * @type {ThreadInfo}
     * @memberof ThreadAndCommentsInfo
     */
    thread: ThreadInfo;
    /**
     * The list of comments.
     * @type {Array<CommentInfo>}
     * @memberof ThreadAndCommentsInfo
     */
    comments: Array<CommentInfo>;
}
/**
 * 
 * @export
 * @interface ThreadInfo
 */
export interface ThreadInfo {
    /**
     * The id of the resource the thread is associated with.
     * @type {string}
     * @memberof ThreadInfo
     */
    resource_id: string;
    /**
     * 
     * @type {ThreadResource}
     * @memberof ThreadInfo
     */
    resource_type: ThreadResource;
    /**
     * The id of the task the thread is grouped under.
     * @type {string}
     * @memberof ThreadInfo
     */
    task_id: string;
    /**
     * 
     * @type {ThreadStatus}
     * @memberof ThreadInfo
     */
    status: ThreadStatus;
    /**
     * 
     * @type {ThreadSeverity}
     * @memberof ThreadInfo
     */
    severity: ThreadSeverity;
    /**
     * The id of the thread.
     * @type {string}
     * @memberof ThreadInfo
     */
    thread_id?: string;
    /**
     * The time the thread was last updated. This includes status and severity updates as well as from comments added.
     * @type {Date}
     * @memberof ThreadInfo
     */
    last_update_time?: Date;
}
/**
 * The type of resource the thread was created in association with.
 * @export
 * @enum {string}
 */
export enum ThreadResource {
    Candidate = <any> 'candidate',
    Turn = <any> 'turn',
    Task = <any> 'task'
}
/**
 * Severity level of a thread.
 * @export
 * @enum {string}
 */
export enum ThreadSeverity {
    Info = <any> 'info',
    Warning = <any> 'warning',
    Error = <any> 'error'
}
/**
 * Statuses of a thread.
 * @export
 * @enum {string}
 */
export enum ThreadStatus {
    Open = <any> 'open',
    Dismissed = <any> 'dismissed',
    Deleted = <any> 'deleted',
    Resolved = <any> 'resolved'
}
/**
 * 
 * @export
 * @interface ThreadsInfo
 */
export interface ThreadsInfo {
    /**
     * The list of threads.
     * @type {Array<ThreadInfo>}
     * @memberof ThreadsInfo
     */
    threads?: Array<ThreadInfo>;
}
/**
 * 
 * @export
 * @interface ThreadsThreadIdBody
 */
export interface ThreadsThreadIdBody {
    /**
     * 
     * @type {ThreadSeverity}
     * @memberof ThreadsThreadIdBody
     */
    severity?: ThreadSeverity;
    /**
     * 
     * @type {ThreadStatus}
     * @memberof ThreadsThreadIdBody
     */
    status?: ThreadStatus;
}
/**
 * 
 * @export
 * @interface TransitionUpdate
 */
export interface TransitionUpdate {
    /**
     * An optional comment to add to the issue.
     * @type {string}
     * @memberof TransitionUpdate
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface TurnList
 */
export interface TurnList {
    /**
     * The ordered list of messages in the chat.
     * @type {Array<SingleTurn>}
     * @memberof TurnList
     */
    turns: Array<SingleTurn>;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * The email address of the user that has a character.ai account associated with it.
     * @type {string}
     * @memberof UserInfo
     */
    email?: string;
    /**
     * The organization id where the user belongs.
     * @type {string}
     * @memberof UserInfo
     */
    org_id?: string;
    /**
     * Whether the user is active.
     * @type {boolean}
     * @memberof UserInfo
     */
    is_active?: boolean;
    /**
     * Whether the user is an admin.
     * @type {boolean}
     * @memberof UserInfo
     */
    is_admin?: boolean;
}
/**
 * 
 * @export
 * @interface UsersInfo
 */
export interface UsersInfo {
    /**
     * The list of users.
     * @type {Array<UserInfo>}
     * @memberof UsersInfo
     */
    users?: Array<UserInfo>;
}
/**
 * The workflow associated with a project and its tasks.
 * @export
 * @enum {string}
 */
export enum WorkflowVersion {
    V1 = <any> 'workflow_v1',
    V2 = <any> 'workflow_v2'
}
/**
 * AnnotationTasksApi - fetch parameter creator
 * @export
 */
export const AnnotationTasksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Append turns
         * @param {TurnList} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendTurns(body: TurnList, task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling appendTurns.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling appendTurns.');
            }
            const localVarPath = `/tasks/{taskId}/chat/turns`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TurnList" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update task state
         * @param {TransitionUpdate} body 
         * @param {TaskState} end_state 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginTaskTransition(body: TransitionUpdate, end_state: TaskState, task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling beginTaskTransition.');
            }
            // verify required parameter 'end_state' is not null or undefined
            if (end_state === null || end_state === undefined) {
                throw new RequiredError('end_state','Required parameter end_state was null or undefined when calling beginTaskTransition.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling beginTaskTransition.');
            }
            const localVarPath = `/tasks/transition/{endState}/{taskId}`
                .replace(`{${"endState"}}`, encodeURIComponent(String(end_state)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransitionUpdate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add comment to thread
         * @param {CommentInfo} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(body: CommentInfo, thread_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createComment.');
            }
            // verify required parameter 'thread_id' is not null or undefined
            if (thread_id === null || thread_id === undefined) {
                throw new RequiredError('thread_id','Required parameter thread_id was null or undefined when calling createComment.');
            }
            const localVarPath = `/threads/{threadId}/comments`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(thread_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create task
         * @param {NewTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(body: NewTask, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTask.');
            }
            const localVarPath = `/tasks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewTask" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create thread
         * @param {ThreadAndCommentsInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread(body: ThreadAndCommentsInfo, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createThread.');
            }
            const localVarPath = `/threads`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ThreadAndCommentsInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Task
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling getTaskInfo.');
            }
            const localVarPath = `/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get threads
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskThreadsWithComments(task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling getTaskThreadsWithComments.');
            }
            const localVarPath = `/tasks/{taskId}/threads`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tasks
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksInfo(state?: TaskStateFilter, options: any = {}): FetchArgs {
            const localVarPath = `/tasks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get threads for resource
         * @param {ThreadResource} resource_type 
         * @param {string} resource_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadsByResource(resource_type: ThreadResource, resource_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'resource_type' is not null or undefined
            if (resource_type === null || resource_type === undefined) {
                throw new RequiredError('resource_type','Required parameter resource_type was null or undefined when calling getThreadsByResource.');
            }
            // verify required parameter 'resource_id' is not null or undefined
            if (resource_id === null || resource_id === undefined) {
                throw new RequiredError('resource_id','Required parameter resource_id was null or undefined when calling getThreadsByResource.');
            }
            const localVarPath = `/threads/resource/{resourceType}/{resourceId}`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resource_type)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resource_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get turns
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTurns(task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling getTurns.');
            }
            const localVarPath = `/tasks/{taskId}/chat/turns`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update annotations
         * @param {NewChatAnnotations} body 
         * @param {string} task_id 
         * @param {string} turn_id 
         * @param {string} candidate_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationCandidateTaskChat(body: NewChatAnnotations, task_id: string, turn_id: string, candidate_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAnnotationCandidateTaskChat.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling updateAnnotationCandidateTaskChat.');
            }
            // verify required parameter 'turn_id' is not null or undefined
            if (turn_id === null || turn_id === undefined) {
                throw new RequiredError('turn_id','Required parameter turn_id was null or undefined when calling updateAnnotationCandidateTaskChat.');
            }
            // verify required parameter 'candidate_id' is not null or undefined
            if (candidate_id === null || candidate_id === undefined) {
                throw new RequiredError('candidate_id','Required parameter candidate_id was null or undefined when calling updateAnnotationCandidateTaskChat.');
            }
            const localVarPath = `/tasks/{taskId}/chat/turns/{turnId}/candidates/{candidateId}/annotations`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)))
                .replace(`{${"turnId"}}`, encodeURIComponent(String(turn_id)))
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidate_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewChatAnnotations" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update annotation ontology for a task
         * @param {OntologyTaskIdBody} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationOntology(body: OntologyTaskIdBody, task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAnnotationOntology.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling updateAnnotationOntology.');
            }
            const localVarPath = `/tasks/ontology/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OntologyTaskIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update thread
         * @param {ThreadsThreadIdBody} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThread(body: ThreadsThreadIdBody, thread_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateThread.');
            }
            // verify required parameter 'thread_id' is not null or undefined
            if (thread_id === null || thread_id === undefined) {
                throw new RequiredError('thread_id','Required parameter thread_id was null or undefined when calling updateThread.');
            }
            const localVarPath = `/threads/{threadId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(thread_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ThreadsThreadIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationTasksApi - functional programming interface
 * @export
 */
export const AnnotationTasksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Append turns
         * @param {TurnList} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendTurns(body: TurnList, task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).appendTurns(body, task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update task state
         * @param {TransitionUpdate} body 
         * @param {TaskState} end_state 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginTaskTransition(body: TransitionUpdate, end_state: TaskState, task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).beginTaskTransition(body, end_state, task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add comment to thread
         * @param {CommentInfo} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(body: CommentInfo, thread_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommentInfo> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).createComment(body, thread_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create task
         * @param {NewTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(body: NewTask, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskInfo> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).createTask(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create thread
         * @param {ThreadAndCommentsInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread(body: ThreadAndCommentsInfo, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ThreadAndCommentsInfo> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).createThread(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Task
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskInfo> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).getTaskInfo(task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get threads
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskThreadsWithComments(task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ThreadAndCommentsInfo> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).getTaskThreadsWithComments(task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get tasks
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksInfo(state?: TaskStateFilter, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TasksInfo> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).getTasksInfo(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get threads for resource
         * @param {ThreadResource} resource_type 
         * @param {string} resource_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadsByResource(resource_type: ThreadResource, resource_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ThreadsInfo> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).getThreadsByResource(resource_type, resource_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get turns
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTurns(task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TurnList> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).getTurns(task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update annotations
         * @param {NewChatAnnotations} body 
         * @param {string} task_id 
         * @param {string} turn_id 
         * @param {string} candidate_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationCandidateTaskChat(body: NewChatAnnotations, task_id: string, turn_id: string, candidate_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).updateAnnotationCandidateTaskChat(body, task_id, turn_id, candidate_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update annotation ontology for a task
         * @param {OntologyTaskIdBody} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationOntology(body: OntologyTaskIdBody, task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).updateAnnotationOntology(body, task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update thread
         * @param {ThreadsThreadIdBody} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThread(body: ThreadsThreadIdBody, thread_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AnnotationTasksApiFetchParamCreator(configuration).updateThread(body, thread_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AnnotationTasksApi - factory interface
 * @export
 */
export const AnnotationTasksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Append turns
         * @param {TurnList} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendTurns(body: TurnList, task_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).appendTurns(body, task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update task state
         * @param {TransitionUpdate} body 
         * @param {TaskState} end_state 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginTaskTransition(body: TransitionUpdate, end_state: TaskState, task_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).beginTaskTransition(body, end_state, task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add comment to thread
         * @param {CommentInfo} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(body: CommentInfo, thread_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).createComment(body, thread_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create task
         * @param {NewTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(body: NewTask, options?: any) {
            return AnnotationTasksApiFp(configuration).createTask(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create thread
         * @param {ThreadAndCommentsInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread(body: ThreadAndCommentsInfo, options?: any) {
            return AnnotationTasksApiFp(configuration).createThread(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Task
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(task_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).getTaskInfo(task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get threads
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskThreadsWithComments(task_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).getTaskThreadsWithComments(task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get tasks
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksInfo(state?: TaskStateFilter, options?: any) {
            return AnnotationTasksApiFp(configuration).getTasksInfo(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get threads for resource
         * @param {ThreadResource} resource_type 
         * @param {string} resource_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadsByResource(resource_type: ThreadResource, resource_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).getThreadsByResource(resource_type, resource_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get turns
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTurns(task_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).getTurns(task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update annotations
         * @param {NewChatAnnotations} body 
         * @param {string} task_id 
         * @param {string} turn_id 
         * @param {string} candidate_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationCandidateTaskChat(body: NewChatAnnotations, task_id: string, turn_id: string, candidate_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).updateAnnotationCandidateTaskChat(body, task_id, turn_id, candidate_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update annotation ontology for a task
         * @param {OntologyTaskIdBody} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationOntology(body: OntologyTaskIdBody, task_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).updateAnnotationOntology(body, task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update thread
         * @param {ThreadsThreadIdBody} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThread(body: ThreadsThreadIdBody, thread_id: string, options?: any) {
            return AnnotationTasksApiFp(configuration).updateThread(body, thread_id, options)(fetch, basePath);
        },
    };
};

/**
 * AnnotationTasksApi - object-oriented interface
 * @export
 * @class AnnotationTasksApi
 * @extends {BaseAPI}
 */
export class AnnotationTasksApi extends BaseAPI {
    /**
     * 
     * @summary Append turns
     * @param {TurnList} body 
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public appendTurns(body: TurnList, task_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).appendTurns(body, task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update task state
     * @param {TransitionUpdate} body 
     * @param {TaskState} end_state 
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public beginTaskTransition(body: TransitionUpdate, end_state: TaskState, task_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).beginTaskTransition(body, end_state, task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add comment to thread
     * @param {CommentInfo} body 
     * @param {string} thread_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public createComment(body: CommentInfo, thread_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).createComment(body, thread_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create task
     * @param {NewTask} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public createTask(body: NewTask, options?: any) {
        return AnnotationTasksApiFp(this.configuration).createTask(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create thread
     * @param {ThreadAndCommentsInfo} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public createThread(body: ThreadAndCommentsInfo, options?: any) {
        return AnnotationTasksApiFp(this.configuration).createThread(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Task
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public getTaskInfo(task_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).getTaskInfo(task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get threads
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public getTaskThreadsWithComments(task_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).getTaskThreadsWithComments(task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get tasks
     * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public getTasksInfo(state?: TaskStateFilter, options?: any) {
        return AnnotationTasksApiFp(this.configuration).getTasksInfo(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get threads for resource
     * @param {ThreadResource} resource_type 
     * @param {string} resource_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public getThreadsByResource(resource_type: ThreadResource, resource_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).getThreadsByResource(resource_type, resource_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get turns
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public getTurns(task_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).getTurns(task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update annotations
     * @param {NewChatAnnotations} body 
     * @param {string} task_id 
     * @param {string} turn_id 
     * @param {string} candidate_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public updateAnnotationCandidateTaskChat(body: NewChatAnnotations, task_id: string, turn_id: string, candidate_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).updateAnnotationCandidateTaskChat(body, task_id, turn_id, candidate_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update annotation ontology for a task
     * @param {OntologyTaskIdBody} body 
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public updateAnnotationOntology(body: OntologyTaskIdBody, task_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).updateAnnotationOntology(body, task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update thread
     * @param {ThreadsThreadIdBody} body 
     * @param {string} thread_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationTasksApi
     */
    public updateThread(body: ThreadsThreadIdBody, thread_id: string, options?: any) {
        return AnnotationTasksApiFp(this.configuration).updateThread(body, thread_id, options)(this.fetch, this.basePath);
    }

}
/**
 * AuthenticationApi - fetch parameter creator
 * @export
 */
export const AuthenticationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create API Key
         * @param {ApiKey} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: ApiKey, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createApiKey.');
            }
            const localVarPath = `/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ApiKey" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete API key
         * @param {string} key_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(key_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'key_id' is not null or undefined
            if (key_id === null || key_id === undefined) {
                throw new RequiredError('key_id','Required parameter key_id was null or undefined when calling deleteApiKey.');
            }
            const localVarPath = `/keys/{keyId}`
                .replace(`{${"keyId"}}`, encodeURIComponent(String(key_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get API keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys(options: any = {}): FetchArgs {
            const localVarPath = `/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options: any = {}): FetchArgs {
            const localVarPath = `/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create API Key
         * @param {ApiKey} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: ApiKey, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiKeyWithKey> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).createApiKey(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete API key
         * @param {string} key_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(key_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).deleteApiKey(key_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get API keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiKeysInfo> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).getApiKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfo> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).getUserInfo(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create API Key
         * @param {ApiKey} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: ApiKey, options?: any) {
            return AuthenticationApiFp(configuration).createApiKey(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete API key
         * @param {string} key_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(key_id: string, options?: any) {
            return AuthenticationApiFp(configuration).deleteApiKey(key_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get API keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys(options?: any) {
            return AuthenticationApiFp(configuration).getApiKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any) {
            return AuthenticationApiFp(configuration).getUserInfo(options)(fetch, basePath);
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Create API Key
     * @param {ApiKey} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public createApiKey(body: ApiKey, options?: any) {
        return AuthenticationApiFp(this.configuration).createApiKey(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete API key
     * @param {string} key_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public deleteApiKey(key_id: string, options?: any) {
        return AuthenticationApiFp(this.configuration).deleteApiKey(key_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get API keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getApiKeys(options?: any) {
        return AuthenticationApiFp(this.configuration).getApiKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getUserInfo(options?: any) {
        return AuthenticationApiFp(this.configuration).getUserInfo(options)(this.fetch, this.basePath);
    }

}
/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Append turns
         * @param {TurnList} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendTurns(body: TurnList, task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling appendTurns.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling appendTurns.');
            }
            const localVarPath = `/tasks/{taskId}/chat/turns`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TurnList" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update task state
         * @param {TransitionUpdate} body 
         * @param {TaskState} end_state 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginTaskTransition(body: TransitionUpdate, end_state: TaskState, task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling beginTaskTransition.');
            }
            // verify required parameter 'end_state' is not null or undefined
            if (end_state === null || end_state === undefined) {
                throw new RequiredError('end_state','Required parameter end_state was null or undefined when calling beginTaskTransition.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling beginTaskTransition.');
            }
            const localVarPath = `/tasks/transition/{endState}/{taskId}`
                .replace(`{${"endState"}}`, encodeURIComponent(String(end_state)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransitionUpdate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create user under organization
         * @param {NewUser} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotatorUser(body: NewUser, org_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAnnotatorUser.');
            }
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling createAnnotatorUser.');
            }
            const localVarPath = `/organizations/{orgId}/users`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewUser" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create API Key
         * @param {ApiKey} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: ApiKey, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createApiKey.');
            }
            const localVarPath = `/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ApiKey" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add comment to thread
         * @param {CommentInfo} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(body: CommentInfo, thread_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createComment.');
            }
            // verify required parameter 'thread_id' is not null or undefined
            if (thread_id === null || thread_id === undefined) {
                throw new RequiredError('thread_id','Required parameter thread_id was null or undefined when calling createComment.');
            }
            const localVarPath = `/threads/{threadId}/comments`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(thread_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create organization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAndJiraProject(body: Organization, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrganizationAndJiraProject.');
            }
            const localVarPath = `/organizations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Organization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create task
         * @param {NewTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(body: NewTask, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTask.');
            }
            const localVarPath = `/tasks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewTask" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create thread
         * @param {ThreadAndCommentsInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread(body: ThreadAndCommentsInfo, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createThread.');
            }
            const localVarPath = `/threads`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ThreadAndCommentsInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user under organization
         * @param {string} org_id String format represents organization id the user belongs to.
         * @param {string} email String format represents the email address of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotatorUser(org_id: string, email: string, options: any = {}): FetchArgs {
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling deleteAnnotatorUser.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling deleteAnnotatorUser.');
            }
            const localVarPath = `/organizations/{org_id}/user/{email}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete API key
         * @param {string} key_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(key_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'key_id' is not null or undefined
            if (key_id === null || key_id === undefined) {
                throw new RequiredError('key_id','Required parameter key_id was null or undefined when calling deleteApiKey.');
            }
            const localVarPath = `/keys/{keyId}`
                .replace(`{${"keyId"}}`, encodeURIComponent(String(key_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get API keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys(options: any = {}): FetchArgs {
            const localVarPath = `/keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(org_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling getOrganization.');
            }
            const localVarPath = `/organizations/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization tasks
         * @param {string} org_id 
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {string} [experiment_name] The experiment name of the tasks to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTasks(org_id: string, state?: TaskStateFilter, experiment_name?: string, options: any = {}): FetchArgs {
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling getOrganizationTasks.');
            }
            const localVarPath = `/organizations/{orgId}/tasks`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (experiment_name !== undefined) {
                localVarQueryParameter['experiment_name'] = experiment_name;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Task
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling getTaskInfo.');
            }
            const localVarPath = `/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get threads
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskThreadsWithComments(task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling getTaskThreadsWithComments.');
            }
            const localVarPath = `/tasks/{taskId}/threads`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tasks
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksInfo(state?: TaskStateFilter, options: any = {}): FetchArgs {
            const localVarPath = `/tasks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get threads for resource
         * @param {ThreadResource} resource_type 
         * @param {string} resource_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadsByResource(resource_type: ThreadResource, resource_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'resource_type' is not null or undefined
            if (resource_type === null || resource_type === undefined) {
                throw new RequiredError('resource_type','Required parameter resource_type was null or undefined when calling getThreadsByResource.');
            }
            // verify required parameter 'resource_id' is not null or undefined
            if (resource_id === null || resource_id === undefined) {
                throw new RequiredError('resource_id','Required parameter resource_id was null or undefined when calling getThreadsByResource.');
            }
            const localVarPath = `/threads/resource/{resourceType}/{resourceId}`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resource_type)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resource_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get turns
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTurns(task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling getTurns.');
            }
            const localVarPath = `/tasks/{taskId}/chat/turns`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options: any = {}): FetchArgs {
            const localVarPath = `/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization users
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInfoForOrg(org_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling getUsersInfoForOrg.');
            }
            const localVarPath = `/organizations/{orgId}/users`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update annotations
         * @param {NewChatAnnotations} body 
         * @param {string} task_id 
         * @param {string} turn_id 
         * @param {string} candidate_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationCandidateTaskChat(body: NewChatAnnotations, task_id: string, turn_id: string, candidate_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAnnotationCandidateTaskChat.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling updateAnnotationCandidateTaskChat.');
            }
            // verify required parameter 'turn_id' is not null or undefined
            if (turn_id === null || turn_id === undefined) {
                throw new RequiredError('turn_id','Required parameter turn_id was null or undefined when calling updateAnnotationCandidateTaskChat.');
            }
            // verify required parameter 'candidate_id' is not null or undefined
            if (candidate_id === null || candidate_id === undefined) {
                throw new RequiredError('candidate_id','Required parameter candidate_id was null or undefined when calling updateAnnotationCandidateTaskChat.');
            }
            const localVarPath = `/tasks/{taskId}/chat/turns/{turnId}/candidates/{candidateId}/annotations`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)))
                .replace(`{${"turnId"}}`, encodeURIComponent(String(turn_id)))
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidate_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewChatAnnotations" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update annotation ontology for a task
         * @param {OntologyTaskIdBody} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationOntology(body: OntologyTaskIdBody, task_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAnnotationOntology.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id','Required parameter task_id was null or undefined when calling updateAnnotationOntology.');
            }
            const localVarPath = `/tasks/ontology/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OntologyTaskIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization
         * @param {OrganizationsOrgIdBody} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: OrganizationsOrgIdBody, org_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrganization.');
            }
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling updateOrganization.');
            }
            const localVarPath = `/organizations/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrganizationsOrgIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update thread
         * @param {ThreadsThreadIdBody} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThread(body: ThreadsThreadIdBody, thread_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateThread.');
            }
            // verify required parameter 'thread_id' is not null or undefined
            if (thread_id === null || thread_id === undefined) {
                throw new RequiredError('thread_id','Required parameter thread_id was null or undefined when calling updateThread.');
            }
            const localVarPath = `/threads/{threadId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(thread_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ThreadsThreadIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Append turns
         * @param {TurnList} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendTurns(body: TurnList, task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).appendTurns(body, task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update task state
         * @param {TransitionUpdate} body 
         * @param {TaskState} end_state 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginTaskTransition(body: TransitionUpdate, end_state: TaskState, task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).beginTaskTransition(body, end_state, task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create user under organization
         * @param {NewUser} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotatorUser(body: NewUser, org_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createAnnotatorUser(body, org_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create API Key
         * @param {ApiKey} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: ApiKey, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiKeyWithKey> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createApiKey(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add comment to thread
         * @param {CommentInfo} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(body: CommentInfo, thread_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommentInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createComment(body, thread_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create organization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAndJiraProject(body: Organization, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createOrganizationAndJiraProject(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create task
         * @param {NewTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(body: NewTask, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createTask(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create thread
         * @param {ThreadAndCommentsInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread(body: ThreadAndCommentsInfo, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ThreadAndCommentsInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createThread(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete user under organization
         * @param {string} org_id String format represents organization id the user belongs to.
         * @param {string} email String format represents the email address of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotatorUser(org_id: string, email: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteAnnotatorUser(org_id, email, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete API key
         * @param {string} key_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(key_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteApiKey(key_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get API keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiKeysInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getApiKeys(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get organization
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(org_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOrganization(org_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get organization tasks
         * @param {string} org_id 
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {string} [experiment_name] The experiment name of the tasks to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTasks(org_id: string, state?: TaskStateFilter, experiment_name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShortTasksInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOrganizationTasks(org_id, state, experiment_name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Task
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTaskInfo(task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get threads
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskThreadsWithComments(task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ThreadAndCommentsInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTaskThreadsWithComments(task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get tasks
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksInfo(state?: TaskStateFilter, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TasksInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTasksInfo(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get threads for resource
         * @param {ThreadResource} resource_type 
         * @param {string} resource_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadsByResource(resource_type: ThreadResource, resource_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ThreadsInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getThreadsByResource(resource_type, resource_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get turns
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTurns(task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TurnList> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTurns(task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getUserInfo(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get organization users
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInfoForOrg(org_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsersInfo> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getUsersInfoForOrg(org_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update annotations
         * @param {NewChatAnnotations} body 
         * @param {string} task_id 
         * @param {string} turn_id 
         * @param {string} candidate_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationCandidateTaskChat(body: NewChatAnnotations, task_id: string, turn_id: string, candidate_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateAnnotationCandidateTaskChat(body, task_id, turn_id, candidate_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update annotation ontology for a task
         * @param {OntologyTaskIdBody} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationOntology(body: OntologyTaskIdBody, task_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateAnnotationOntology(body, task_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update organization
         * @param {OrganizationsOrgIdBody} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: OrganizationsOrgIdBody, org_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateOrganization(body, org_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update thread
         * @param {ThreadsThreadIdBody} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThread(body: ThreadsThreadIdBody, thread_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateThread(body, thread_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Append turns
         * @param {TurnList} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendTurns(body: TurnList, task_id: string, options?: any) {
            return DefaultApiFp(configuration).appendTurns(body, task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update task state
         * @param {TransitionUpdate} body 
         * @param {TaskState} end_state 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginTaskTransition(body: TransitionUpdate, end_state: TaskState, task_id: string, options?: any) {
            return DefaultApiFp(configuration).beginTaskTransition(body, end_state, task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create user under organization
         * @param {NewUser} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotatorUser(body: NewUser, org_id: string, options?: any) {
            return DefaultApiFp(configuration).createAnnotatorUser(body, org_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create API Key
         * @param {ApiKey} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: ApiKey, options?: any) {
            return DefaultApiFp(configuration).createApiKey(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add comment to thread
         * @param {CommentInfo} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(body: CommentInfo, thread_id: string, options?: any) {
            return DefaultApiFp(configuration).createComment(body, thread_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create organization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAndJiraProject(body: Organization, options?: any) {
            return DefaultApiFp(configuration).createOrganizationAndJiraProject(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create task
         * @param {NewTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(body: NewTask, options?: any) {
            return DefaultApiFp(configuration).createTask(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create thread
         * @param {ThreadAndCommentsInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread(body: ThreadAndCommentsInfo, options?: any) {
            return DefaultApiFp(configuration).createThread(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete user under organization
         * @param {string} org_id String format represents organization id the user belongs to.
         * @param {string} email String format represents the email address of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotatorUser(org_id: string, email: string, options?: any) {
            return DefaultApiFp(configuration).deleteAnnotatorUser(org_id, email, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete API key
         * @param {string} key_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(key_id: string, options?: any) {
            return DefaultApiFp(configuration).deleteApiKey(key_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get API keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys(options?: any) {
            return DefaultApiFp(configuration).getApiKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get organization
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(org_id: string, options?: any) {
            return DefaultApiFp(configuration).getOrganization(org_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get organization tasks
         * @param {string} org_id 
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {string} [experiment_name] The experiment name of the tasks to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTasks(org_id: string, state?: TaskStateFilter, experiment_name?: string, options?: any) {
            return DefaultApiFp(configuration).getOrganizationTasks(org_id, state, experiment_name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Task
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(task_id: string, options?: any) {
            return DefaultApiFp(configuration).getTaskInfo(task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get threads
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskThreadsWithComments(task_id: string, options?: any) {
            return DefaultApiFp(configuration).getTaskThreadsWithComments(task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get tasks
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksInfo(state?: TaskStateFilter, options?: any) {
            return DefaultApiFp(configuration).getTasksInfo(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get threads for resource
         * @param {ThreadResource} resource_type 
         * @param {string} resource_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadsByResource(resource_type: ThreadResource, resource_id: string, options?: any) {
            return DefaultApiFp(configuration).getThreadsByResource(resource_type, resource_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get turns
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTurns(task_id: string, options?: any) {
            return DefaultApiFp(configuration).getTurns(task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any) {
            return DefaultApiFp(configuration).getUserInfo(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get organization users
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInfoForOrg(org_id: string, options?: any) {
            return DefaultApiFp(configuration).getUsersInfoForOrg(org_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update annotations
         * @param {NewChatAnnotations} body 
         * @param {string} task_id 
         * @param {string} turn_id 
         * @param {string} candidate_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationCandidateTaskChat(body: NewChatAnnotations, task_id: string, turn_id: string, candidate_id: string, options?: any) {
            return DefaultApiFp(configuration).updateAnnotationCandidateTaskChat(body, task_id, turn_id, candidate_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update annotation ontology for a task
         * @param {OntologyTaskIdBody} body 
         * @param {string} task_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotationOntology(body: OntologyTaskIdBody, task_id: string, options?: any) {
            return DefaultApiFp(configuration).updateAnnotationOntology(body, task_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update organization
         * @param {OrganizationsOrgIdBody} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: OrganizationsOrgIdBody, org_id: string, options?: any) {
            return DefaultApiFp(configuration).updateOrganization(body, org_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update thread
         * @param {ThreadsThreadIdBody} body 
         * @param {string} thread_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThread(body: ThreadsThreadIdBody, thread_id: string, options?: any) {
            return DefaultApiFp(configuration).updateThread(body, thread_id, options)(fetch, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Append turns
     * @param {TurnList} body 
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appendTurns(body: TurnList, task_id: string, options?: any) {
        return DefaultApiFp(this.configuration).appendTurns(body, task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update task state
     * @param {TransitionUpdate} body 
     * @param {TaskState} end_state 
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public beginTaskTransition(body: TransitionUpdate, end_state: TaskState, task_id: string, options?: any) {
        return DefaultApiFp(this.configuration).beginTaskTransition(body, end_state, task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create user under organization
     * @param {NewUser} body 
     * @param {string} org_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAnnotatorUser(body: NewUser, org_id: string, options?: any) {
        return DefaultApiFp(this.configuration).createAnnotatorUser(body, org_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create API Key
     * @param {ApiKey} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createApiKey(body: ApiKey, options?: any) {
        return DefaultApiFp(this.configuration).createApiKey(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add comment to thread
     * @param {CommentInfo} body 
     * @param {string} thread_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createComment(body: CommentInfo, thread_id: string, options?: any) {
        return DefaultApiFp(this.configuration).createComment(body, thread_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create organization
     * @param {Organization} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrganizationAndJiraProject(body: Organization, options?: any) {
        return DefaultApiFp(this.configuration).createOrganizationAndJiraProject(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create task
     * @param {NewTask} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTask(body: NewTask, options?: any) {
        return DefaultApiFp(this.configuration).createTask(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create thread
     * @param {ThreadAndCommentsInfo} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createThread(body: ThreadAndCommentsInfo, options?: any) {
        return DefaultApiFp(this.configuration).createThread(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete user under organization
     * @param {string} org_id String format represents organization id the user belongs to.
     * @param {string} email String format represents the email address of the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAnnotatorUser(org_id: string, email: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteAnnotatorUser(org_id, email, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete API key
     * @param {string} key_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiKey(key_id: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteApiKey(key_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get API keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiKeys(options?: any) {
        return DefaultApiFp(this.configuration).getApiKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get organization
     * @param {string} org_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOrganization(org_id: string, options?: any) {
        return DefaultApiFp(this.configuration).getOrganization(org_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get organization tasks
     * @param {string} org_id 
     * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
     * @param {string} [experiment_name] The experiment name of the tasks to fetch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOrganizationTasks(org_id: string, state?: TaskStateFilter, experiment_name?: string, options?: any) {
        return DefaultApiFp(this.configuration).getOrganizationTasks(org_id, state, experiment_name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Task
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTaskInfo(task_id: string, options?: any) {
        return DefaultApiFp(this.configuration).getTaskInfo(task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get threads
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTaskThreadsWithComments(task_id: string, options?: any) {
        return DefaultApiFp(this.configuration).getTaskThreadsWithComments(task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get tasks
     * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTasksInfo(state?: TaskStateFilter, options?: any) {
        return DefaultApiFp(this.configuration).getTasksInfo(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get threads for resource
     * @param {ThreadResource} resource_type 
     * @param {string} resource_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getThreadsByResource(resource_type: ThreadResource, resource_id: string, options?: any) {
        return DefaultApiFp(this.configuration).getThreadsByResource(resource_type, resource_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get turns
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTurns(task_id: string, options?: any) {
        return DefaultApiFp(this.configuration).getTurns(task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserInfo(options?: any) {
        return DefaultApiFp(this.configuration).getUserInfo(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get organization users
     * @param {string} org_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsersInfoForOrg(org_id: string, options?: any) {
        return DefaultApiFp(this.configuration).getUsersInfoForOrg(org_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update annotations
     * @param {NewChatAnnotations} body 
     * @param {string} task_id 
     * @param {string} turn_id 
     * @param {string} candidate_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAnnotationCandidateTaskChat(body: NewChatAnnotations, task_id: string, turn_id: string, candidate_id: string, options?: any) {
        return DefaultApiFp(this.configuration).updateAnnotationCandidateTaskChat(body, task_id, turn_id, candidate_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update annotation ontology for a task
     * @param {OntologyTaskIdBody} body 
     * @param {string} task_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAnnotationOntology(body: OntologyTaskIdBody, task_id: string, options?: any) {
        return DefaultApiFp(this.configuration).updateAnnotationOntology(body, task_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update organization
     * @param {OrganizationsOrgIdBody} body 
     * @param {string} org_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateOrganization(body: OrganizationsOrgIdBody, org_id: string, options?: any) {
        return DefaultApiFp(this.configuration).updateOrganization(body, org_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update thread
     * @param {ThreadsThreadIdBody} body 
     * @param {string} thread_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateThread(body: ThreadsThreadIdBody, thread_id: string, options?: any) {
        return DefaultApiFp(this.configuration).updateThread(body, thread_id, options)(this.fetch, this.basePath);
    }

}
/**
 * OrganizationsApi - fetch parameter creator
 * @export
 */
export const OrganizationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create user under organization
         * @param {NewUser} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotatorUser(body: NewUser, org_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAnnotatorUser.');
            }
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling createAnnotatorUser.');
            }
            const localVarPath = `/organizations/{orgId}/users`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewUser" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create organization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAndJiraProject(body: Organization, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrganizationAndJiraProject.');
            }
            const localVarPath = `/organizations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Organization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user under organization
         * @param {string} org_id String format represents organization id the user belongs to.
         * @param {string} email String format represents the email address of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotatorUser(org_id: string, email: string, options: any = {}): FetchArgs {
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling deleteAnnotatorUser.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling deleteAnnotatorUser.');
            }
            const localVarPath = `/organizations/{org_id}/user/{email}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(org_id)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(org_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling getOrganization.');
            }
            const localVarPath = `/organizations/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization tasks
         * @param {string} org_id 
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {string} [experiment_name] The experiment name of the tasks to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTasks(org_id: string, state?: TaskStateFilter, experiment_name?: string, options: any = {}): FetchArgs {
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling getOrganizationTasks.');
            }
            const localVarPath = `/organizations/{orgId}/tasks`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (experiment_name !== undefined) {
                localVarQueryParameter['experiment_name'] = experiment_name;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization users
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInfoForOrg(org_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling getUsersInfoForOrg.');
            }
            const localVarPath = `/organizations/{orgId}/users`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization
         * @param {OrganizationsOrgIdBody} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: OrganizationsOrgIdBody, org_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrganization.');
            }
            // verify required parameter 'org_id' is not null or undefined
            if (org_id === null || org_id === undefined) {
                throw new RequiredError('org_id','Required parameter org_id was null or undefined when calling updateOrganization.');
            }
            const localVarPath = `/organizations/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(org_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrganizationsOrgIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create user under organization
         * @param {NewUser} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotatorUser(body: NewUser, org_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfo> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).createAnnotatorUser(body, org_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create organization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAndJiraProject(body: Organization, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).createOrganizationAndJiraProject(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete user under organization
         * @param {string} org_id String format represents organization id the user belongs to.
         * @param {string} email String format represents the email address of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotatorUser(org_id: string, email: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfo> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).deleteAnnotatorUser(org_id, email, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get organization
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(org_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).getOrganization(org_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get organization tasks
         * @param {string} org_id 
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {string} [experiment_name] The experiment name of the tasks to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTasks(org_id: string, state?: TaskStateFilter, experiment_name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShortTasksInfo> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).getOrganizationTasks(org_id, state, experiment_name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get organization users
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInfoForOrg(org_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsersInfo> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).getUsersInfoForOrg(org_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update organization
         * @param {OrganizationsOrgIdBody} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: OrganizationsOrgIdBody, org_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).updateOrganization(body, org_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create user under organization
         * @param {NewUser} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotatorUser(body: NewUser, org_id: string, options?: any) {
            return OrganizationsApiFp(configuration).createAnnotatorUser(body, org_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create organization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAndJiraProject(body: Organization, options?: any) {
            return OrganizationsApiFp(configuration).createOrganizationAndJiraProject(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete user under organization
         * @param {string} org_id String format represents organization id the user belongs to.
         * @param {string} email String format represents the email address of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotatorUser(org_id: string, email: string, options?: any) {
            return OrganizationsApiFp(configuration).deleteAnnotatorUser(org_id, email, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get organization
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(org_id: string, options?: any) {
            return OrganizationsApiFp(configuration).getOrganization(org_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get organization tasks
         * @param {string} org_id 
         * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
         * @param {string} [experiment_name] The experiment name of the tasks to fetch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTasks(org_id: string, state?: TaskStateFilter, experiment_name?: string, options?: any) {
            return OrganizationsApiFp(configuration).getOrganizationTasks(org_id, state, experiment_name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get organization users
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInfoForOrg(org_id: string, options?: any) {
            return OrganizationsApiFp(configuration).getUsersInfoForOrg(org_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update organization
         * @param {OrganizationsOrgIdBody} body 
         * @param {string} org_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: OrganizationsOrgIdBody, org_id: string, options?: any) {
            return OrganizationsApiFp(configuration).updateOrganization(body, org_id, options)(fetch, basePath);
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary Create user under organization
     * @param {NewUser} body 
     * @param {string} org_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createAnnotatorUser(body: NewUser, org_id: string, options?: any) {
        return OrganizationsApiFp(this.configuration).createAnnotatorUser(body, org_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create organization
     * @param {Organization} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createOrganizationAndJiraProject(body: Organization, options?: any) {
        return OrganizationsApiFp(this.configuration).createOrganizationAndJiraProject(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete user under organization
     * @param {string} org_id String format represents organization id the user belongs to.
     * @param {string} email String format represents the email address of the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteAnnotatorUser(org_id: string, email: string, options?: any) {
        return OrganizationsApiFp(this.configuration).deleteAnnotatorUser(org_id, email, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get organization
     * @param {string} org_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganization(org_id: string, options?: any) {
        return OrganizationsApiFp(this.configuration).getOrganization(org_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get organization tasks
     * @param {string} org_id 
     * @param {TaskStateFilter} [state] The state(s) of the tasks to fetch. The default value is &#x60;all&#x60;.
     * @param {string} [experiment_name] The experiment name of the tasks to fetch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationTasks(org_id: string, state?: TaskStateFilter, experiment_name?: string, options?: any) {
        return OrganizationsApiFp(this.configuration).getOrganizationTasks(org_id, state, experiment_name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get organization users
     * @param {string} org_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getUsersInfoForOrg(org_id: string, options?: any) {
        return OrganizationsApiFp(this.configuration).getUsersInfoForOrg(org_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update organization
     * @param {OrganizationsOrgIdBody} body 
     * @param {string} org_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganization(body: OrganizationsOrgIdBody, org_id: string, options?: any) {
        return OrganizationsApiFp(this.configuration).updateOrganization(body, org_id, options)(this.fetch, this.basePath);
    }

}
